/* eslint-disable no-unused-vars */
import actTypes from './data/act-types.js';
import practitioners from './data/practitioners.js';
import diseaseCodes from './data/disease-codes.js';
import allActs from './data/acts.js';
import teethNum from './data/teeth.js';

export const terminalAPI = {
  getToken: () => '1',
  getActTypes: async () => actTypes,
  getPatient: async ({ patientId }) => {
    await new Promise(resolve => setTimeout(resolve, 500));
    if (patientId === '420911') {
      return {
        id: `SEN${patientId}`,
        name: 'Ouedraogo Eric Patrick',
        birthday: new Date('1976-09-12'),
        status: 'A',
      };
    }
    if (patientId === '1515') {
      return {
        id: patientId,
        name: 'Bidou Zurick',
        birthday: new Date('1989-09-30'),
        status: 'E',
      };
    }
    if (patientId === '6') {
      return {
        id: patientId,
        name: 'Kantondé Gnimavo Cyrus',
        birthday: new Date('1988-10*30'),
        status: 'E',
      };
    }
    return null;
  },
  getPractitioners: async ({ actTypeId }) => practitioners,
  getDiseaseCodes: async () => diseaseCodes,
  getActs: async (actTypeId, practitionerId, searchText = '') => {
    const search = searchText.toLowerCase();
    return allActs
      .find(({ type }) => type === Number(actTypeId))
      .acts.filter(
        ({ id, name }) => id.toLowerCase().includes(search) || name.toLowerCase().includes(search),
      )
      .slice(0, 10);
  },
  getActPrice: async (actId, practitionerId, patientId, remoteQuantity) => ({
    price: 0,
    freePrice: true,
  }),
  getTeethNum: async search => {
    if (search !== '') {
      return teethNum.filter(t => t.name.search(search) !== -1);
    }
    return teethNum;
  },
  getFds: numFds => Promise.resolve({}),
  /**
   * @type {(pec: import('../src/types').IPecRequest) => Promise<any>}
   */
  getFdsFromPec: pec => Promise.resolve({}),
};
