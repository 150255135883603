export default [
  {
    id: 2,
    name: 'Imagerie',
  },
  {
    id: 3,
    name: 'Actes de Biologie',
  },
  {
    id: 4,
    name: 'Pharmacie',
  },
  {
    id: 5,
    name: 'Optique',
  },
  {
    id: 6,
    name: 'Soins Dentaires',
  },
  {
    id: 7,
    name: 'Soins',
  },
  {
    id: 8,
    name: 'Hospitalisation',
  },
  {
    id: 9,
    name: 'Transport',
  },
  {
    id: 10,
    name: 'Auxiliaires Médicaux',
  },
  {
    id: 12,
    name: 'Actes de Spécialité',
  },
];
