export default /* 1 */
[
  {
    type: 5,
    acts: [
      {
        id: 'OPT',
        name: 'Verres et montures',
      },
      {
        id: 'OVER',
        name: 'Verres optiques',
      },
      {
        id: 'OLUN',
        name: 'Montures Optiques',
      },
      {
        id: 'OLEN',
        name: 'Lentilles',
      },
    ],
  },
  {
    type: 8,
    acts: [
      {
        id: 'HJR',
        name: 'Journée de réanimation',
      },
      {
        id: 'HJA',
        name: "Journée d'hospitalisation accompagnant",
      },
      {
        id: 'HHM',
        name: 'Hospitalisation médicale',
      },
      {
        id: 'HHC',
        name: 'Hospitalisation chirurgicale',
      },
      {
        id: 'HAM',
        name: 'Accouchement multiple',
      },
      {
        id: 'HJH',
        name: "Journée d'hospitalisation",
      },
      {
        id: 'HAC',
        name: 'Accouchement chirurgical',
      },
      {
        id: 'HA',
        name: 'Accouchement simple',
      },
    ],
  },
  {
    type: 10,
    acts: [
      {
        id: 'AAMC',
        name: 'Séance de kinésithérapie',
      },
    ],
  },
  {
    type: 6,
    acts: [
      {
        id: 'DSC',
        name: 'Soins conservateurs',
      },
      {
        id: '6084',
        name: 'Ablation d’une lésion de glande parotide',
      },
      {
        id: '6081',
        name: 'Lithiases salivaires Proche de l’ostium',
      },
      {
        id: '6080',
        name: 'Disjonction crânio-faciale avec déplacement',
      },
      {
        id: '6078',
        name: 'Réduction des fractures 2 traits ouverts',
      },
      {
        id: '6077',
        name: 'Réduction des fractures 2 traits non ouvertes',
      },
      {
        id: '6076',
        name: 'Réduction des fractures 1 trait ouverte',
      },
      {
        id: '6083',
        name: 'Fistule salivaire cutanée',
      },
      {
        id: '6075',
        name: 'Réduction des fractures 1 trait non ouverte',
      },
      {
        id: '6073',
        name: 'Réduction d’une luxation de l’ATM',
      },
      {
        id: '6069',
        name: 'Réparation d’une lèvre (tumeur ou traumatisme) Partielle',
      },
      {
        id: '6066',
        name: 'Tumeur Bénigne de diamètre à partir de 0,5cm',
      },
      {
        id: '6065',
        name: 'Ostéite avec séquestre Etendue à plusieurs dents',
      },
      {
        id: '6074',
        name: 'Réduction sanglante de l’ATM (ankylose temporo-mandibulaire)',
      },
      {
        id: '6063',
        name: 'Curetage alvéolaire',
      },
      {
        id: '6061',
        name: 'Gingivectomie Etendue à une demi arcade ou de canine à canine',
      },
      {
        id: '6058',
        name:
          'Exérèse chirurgicale d’un Kyste étendu aux apex de deux dents et nécessitant une trépanation osseuse',
      },
      {
        id: '6067',
        name: 'Tumeur Maligne de diamètre à partir de 0,5cm',
      },
      {
        id: '6056',
        name: 'Exérèse chirurgicale d’un kyste (radio obligatoire)',
      },
      {
        id: '6053',
        name:
          'Régularisation d’une crête alvéolaire avec suture gingivale Etendue de canine à canine ou à un hémi maxillaire',
      },
    ],
  },
  {
    type: 2,
    acts: [
      {
        id: '5006',
        name: 'ECHOGRAPHIE',
      },
      {
        id: '2170',
        name: 'IRM HEPATIQUE AVEC INJECTION',
      },
      {
        id: '2168',
        name: 'IRM DORSAL AVEC INJECTION',
      },
      {
        id: '2165',
        name: 'IRM CERVICAL AVEC INJECTION',
      },
      {
        id: '2164',
        name: 'IRM DE L’HYPOPHYSE AVEC INJECTION',
      },
      {
        id: '2162',
        name: 'IRM LOMBAIRE SANS INJECTION',
      },
      {
        id: '2160',
        name: 'IRM PELVIEN SANS INJECTION',
      },
      {
        id: '2159',
        name: 'IRM SURRENALE SANS INJECTION',
      },
      {
        id: '2157',
        name: 'IRM DE L’HYPOPHYSE SANS INJECTION',
      },
      {
        id: '2163',
        name: 'IRM CEREBRAL AVEC INJECTION',
      },
      {
        id: '2156',
        name: 'IRM CEREBRAL SANS INJECTION',
      },
      {
        id: '2155',
        name: 'Uroscanner AVEC INJECTION',
      },
      {
        id: '2154',
        name: 'SCANNER/TDM  abdominale et abdomino-pelvienne AVEC INJECTION',
      },
      {
        id: '2152',
        name: 'SCANNER/TDM du rachis à 3 étages AVEC INJECTION',
      },
      {
        id: '2149',
        name: 'SCANNER/TDM du rachis dorsal AVEC INJECTION',
      },
      {
        id: '2145',
        name: 'SCANNER/TDM des rochers, Hypophyses AVEC INJECTION',
      },
      {
        id: '2158',
        name: 'IRM CERVICAL SANS INJECTION',
      },
      {
        id: '2140',
        name: 'SCANNER/TDM  abdominale et abdomino-pelvienne SANS INJECTION',
      },
      {
        id: '2139',
        name: 'Angioscanner AVEC INJECTION',
      },
      {
        id: '2138',
        name: 'SCANNER/TDM du thorax SANS INJECTION',
      },
    ],
  },
  {
    type: 12,
    acts: [
      {
        id: 'ECG',
        name: 'ECG/ELECTROCARDIOGRAMME',
      },
      {
        id: '5017',
        name: 'LASER YAG ',
      },
      {
        id: '5016',
        name: 'VISION DES COULEURS ',
      },
      {
        id: '5015',
        name: 'DEMARCHE POUR EVACUATION',
      },
      {
        id: '5012',
        name: 'PACHYMETRIE',
      },
      {
        id: '5011',
        name: 'FO AU BLOC',
      },
      {
        id: '5010',
        name: 'RETINOGRAPHIE',
      },
      {
        id: '5009',
        name: 'ANGIOGRAPHIE',
      },
      {
        id: '5008',
        name: 'CHAMP VISUEL AUTOMATISE',
      },
      {
        id: '5003',
        name: 'GONIOSCOPIE',
      },
      {
        id: '5007',
        name: 'LAVAGE VOIE LACRYMALE',
      },
      {
        id: '5002',
        name: 'V3M/Verre à trois miroirs',
      },
      {
        id: '1008',
        name: 'EMG/ELECTROMYOGRAMME',
      },
      {
        id: '22',
        name: 'EXTRACTION CORPS ETRANGERS (FOSSE NASALE/OREILLE)',
      },
      {
        id: '5019',
        name: 'LASER SLT',
      },
      {
        id: '5018',
        name: 'PHOTOCOAGULATION LASER',
      },
      {
        id: '1007',
        name: 'SPI',
      },
      {
        id: '5013',
        name: 'INJECTION SOUS CONJONCTIVALE',
      },
      {
        id: '1006',
        name: 'PES/POTENTIELS EVOQUES SOMESTHESIQUES',
      },
      {
        id: '20',
        name: 'LAVAGE OREILLE (UN OU DEUX)',
      },
    ],
  },
  {
    type: 3,
    acts: [
      {
        id: 'GEDP',
        name: 'GOUTTE EPAISSE / GEDP',
      },
      {
        id: 'CRP',
        name: 'PROTEINE C REACTIF/CRP',
      },
      {
        id: 'BPB',
        name: 'Prélèvement sanguin',
      },
      {
        id: 'B4',
        name: 'CALCIUM URINAIRE',
      },
      {
        id: 'B30',
        name: 'FACTEURS RHUMATOIDES',
      },
      {
        id: 'B27',
        name: 'HEMATIES LEUCOCYTES MINUTES /HLM',
      },
      {
        id: 'B26',
        name: 'COPROCULTURE',
      },
      {
        id: 'B19',
        name: 'Prélèvement urinaire (PU) + ATB',
      },
      {
        id: 'B34',
        name: 'Microalbuminurie des 24h',
      },
      {
        id: 'B18',
        name: 'ECBU',
      },
      {
        id: 'B17',
        name: 'CULOT URINAIRE',
      },
      {
        id: 'B15',
        name: 'Antifongigramme',
      },
      {
        id: 'B13',
        name: 'RECHERCHE DES OEUFS DE BILHARZIE',
      },
      {
        id: 'B12',
        name: 'COPROLOGIE PARASITAIRE COMPLETE',
      },
      {
        id: 'B1',
        name: 'GROUPAGE SANGUIN/RHESUS ou ./Gs/Rh',
      },
      {
        id: '3998055',
        name: 'SPERMOCULTURE+ATB',
      },
      {
        id: '3998053',
        name: 'PRELEVEMENT DE PUS+ATB',
      },
      {
        id: '3998052',
        name: 'PRELEVEMENT DE GORGE+ATB',
      },
      {
        id: '3998056',
        name: 'HEMOCULTURE+ATB',
      },
      {
        id: '3998049',
        name: 'KOP / AKOP',
      },
    ],
  },
  {
    type: 1,
    acts: [
      {
        id: 'CS',
        name: 'Consultation spécialiste',
      },
      {
        id: 'CCD',
        name: 'Consultation dentaire',
      },
      {
        id: 'Cag',
        name: 'Consultation professeur',
      },
      {
        id: 'C',
        name: 'Consultation généraliste',
      },
    ],
  },
  {
    type: 7,
    acts: [
      {
        id: 'SFSP',
        name: 'Soins et pansements',
      },
      {
        id: '55',
        name: 'SERINGUE 50ML VRAC *BOUT AIGUILLE*',
      },
      {
        id: '53',
        name: 'SERINGUE 50ML VRAC *BOUT CONIQUE*',
      },
      {
        id: '52',
        name: 'SERINGUE 20ML VRAC',
      },
      {
        id: '50',
        name: 'SERINGUE 5ML VRAC',
      },
      {
        id: '41',
        name: 'MAGNESIUM SULFATE LVS 1,5G10ML',
      },
      {
        id: '40',
        name: 'FUROSEMIDE GENERIQUE 2MG/2ML INJ VRAC ',
      },
      {
        id: '39',
        name: 'FUROSEMIDE 200MG/2ML INJ VRAC',
      },
      {
        id: '37',
        name: 'POTASSIUM CH 10% AMP 10ML / KCL',
      },
      {
        id: '3',
        name: 'Pansements',
      },
      {
        id: '16',
        name: 'Photothérapie (par jour)',
      },
      {
        id: '1544',
        name: 'NUCLEO CMP AMP INJ ',
      },
      {
        id: '1542',
        name: 'NOVALGIN INJ AMP 5ML',
      },
      {
        id: '1540',
        name: 'MENEM 500MG INJ FL/1',
      },
      {
        id: '1538',
        name: 'ANDROTARDYL 250MG A.INJ B/1',
      },
      {
        id: '1536',
        name: 'FAIRCEF 1G INJ B/1+1',
      },
      {
        id: '1535',
        name: 'FERRON 20MG/ML INJ AMP ',
      },
      {
        id: '1534',
        name: 'PANZITOP 40MG INJ F/1',
      },
      {
        id: '2',
        name: 'Prélèvement sanguin',
      },
      {
        id: '1529',
        name: 'CATHETER BRAVO PALUTECH G16',
      },
    ],
  },
  {
    type: 4,
    acts: [
      {
        id: 'UBI9001484',
        name: 'FUCICORT 20MG 1MG G CR T 15G',
      },
      {
        id: 'UBI9000438',
        name: 'PROPOFAN GEL B 15',
      },
      {
        id: 'UBI9000214',
        name: 'DICYNONE 500MG GEL B 20',
      },
      {
        id: 'UBI8306098',
        name: 'VISCODRIL SP F 100ML',
      },
      {
        id: 'UBI8301824',
        name: 'BENDEX 200MG 5ML SUSP BUV F 10ML HP',
      },
      {
        id: 'UBI8293183',
        name: 'LARENS PEAU CLAIRE LOT F 90ML',
      },
      {
        id: 'UBI8276049',
        name: 'PEAUNETT BASIQUE SOL F 100ML',
      },
      {
        id: 'UBI8269612',
        name: 'PARACETAMOL 500MG CP 2BL12*CRE',
      },
      {
        id: 'UBI8240330',
        name: 'VASELINE PURE VENDOME 50ML',
      },
      {
        id: 'UBI8239143',
        name: 'TRICOGYN 500MG CPR B 4',
      },
      {
        id: 'UBI8223484',
        name: 'PEAUNETT LIS 2% SOL F 125ML',
      },
      {
        id: 'UBI8218052',
        name: 'VITAMINES E FORTE MEPACO GEL B 20',
      },
      {
        id: 'UBI8099759',
        name: 'BRUMED 100MG 5ML SUSP BUV F 100ML',
      },
      {
        id: 'UBI8099647',
        name: 'MONAAMI CPR B 20',
      },
      {
        id: 'UBI8099587',
        name: 'BECLAV 500MG 125MG CPR B 20',
      },
      {
        id: 'UBI8099481',
        name: 'ALBENDAZOLE UBI 400MG CPR 50BL1',
      },
      {
        id: 'UBI8099392',
        name: 'ARCET 120MG 5ML SUSP BUV F 60ML',
      },
      {
        id: 'UBI8099067',
        name: 'OMESAFE 20MG GEL 10BL10',
      },
      {
        id: 'UBI8099021',
        name: 'AMINOHAEM GEL B 30',
      },
      {
        id: 'UBI8098702',
        name: 'DYNAPAR TAB 50MG 500MG CPR 10BL VRAC',
      },
    ],
  },
];
