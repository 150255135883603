export default /* 1 */
[
    "100",
    "1004",
    "1008",
    "102",
    "106",
    "110",
    "1104",
    "1110",
    "113",
    "116",
    "118",
    "120",
    "123",
    "127",
    "14",
    "152",
    "156",
    "160",
    "164",
    "170",
    "174",
    "18",
    "20",
    "202",
    "206",
    "210",
    "23",
    "252",
    "256",
    "260",
    "30",
    "302",
    "306",
    "310",
    "313",
    "316",
    "32",
    "354",
    "358",
    "360",
    "362",
    "38",
    "40",
    "404",
    "410",
    "414",
    "418",
    "420",
    "450",
    "454",
    "460",
    "464",
    "468",
    "500",
    "504",
    "510",
    "514",
    "517",
    "520",
    "530",
    "552",
    "556",
    "56",
    "562",
    "566",
    "572",
    "58",
    "580",
    "600",
    "608",
    "612",
    "616",
    "619",
    "620",
    "624",
    "628",
    "640",
    "654",
    "66",
    "68",
    "700",
    "720",
    "754",
    "758",
    "770",
    "802",
    "806",
    "810",
    "814",
    "820",
    "852",
    "856",
    "870",
    "904",
    "908",
    "912",
    "954",
    "958",
    "964",
    "970",
    "10",
    "1002",
    "1006",
    "1010",
    "104",
    "108",
    "1102",
    "1106",
    "112",
    "114",
    "117",
    "12",
    "122",
    "124",
    "130",
    "150",
    "154",
    "16",
    "162",
    "166",
    "172",
    "176",
    "180",
    "200",
    "204",
    "208",
    "22",
    "24",
    "254",
    "26",
    "28",
    "301",
    "304",
    "308",
    "312",
    "314",
    "318",
    "320",
    "34",
    "352",
    "356",
    "370",
    "402",
    "406",
    "408",
    "412",
    "416",
    "42",
    "430",
    "452",
    "458",
    "462",
    "466",
    "480",
    "502",
    "506",
    "512",
    "516",
    "518",
    "522",
    "524",
    "54",
    "554",
    "558",
    "560",
    "564",
    "568",
    "570",
    "574",
    "60",
    "602",
    "606",
    "610",
    "614",
    "618",
    "62",
    "622",
    "626",
    "64",
    "652",
    "656",
    "660",
    "70",
    "704",
    "752",
    "756",
    "760",
    "80",
    "804",
    "808",
    "812",
    "816",
    "850",
    "854",
    "858",
    "902",
    "906",
    "910",
    "920",
    "956",
    "960",
    "966",
    "990"
];