export default /* 1 */
[
    {
        "id" : "2",
        "name" : "GOUDJINOU Raphaël"
    },
    {
        "id" : "5",
        "name" : "LAURIANO Faridath"
    },
    {
        "id" : "6",
        "name" : "ATIKPAHOUN Alphonse"
    },
    {
        "id" : "7",
        "name" : "AGBOTON Hippolyte"
    },
    {
        "id" : "8",
        "name" : "HOUENASSI Martin"
    },
    {
        "id" : "9",
        "name" : "de SOUZA José"
    },
    {
        "id" : "10",
        "name" : "KOLODY Zoya"
    },
    {
        "id" : "11",
        "name" : "DJINADOU Ramalath"
    },
    {
        "id" : "12",
        "name" : "KOUNDE VIDEGLA Jeanne"
    },
    {
        "id" : "13",
        "name" : "TCHALLA Désiré"
    }
];