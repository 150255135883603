export default [
  {
    id: 11,
    name: '11',
    hmtlId: 'teeth11',
  },
  {
    id: 12,
    name: '12',
    hmtlId: 'teeth12',
  },
  {
    id: 13,
    name: '13',
    hmtlId: 'teeth13',
  },
  {
    id: 14,
    name: '14',
    hmtlId: 'teeth14',
  },
  {
    id: 15,
    name: '15',
    hmtlId: 'teeth15',
  },
  {
    id: 16,
    name: '16',
    hmtlId: 'teeth16',
  },
  {
    id: 17,
    name: '17',
    hmtlId: 'teeth17',
  },
  {
    id: 18,
    name: '18',
    hmtlId: 'teeth18',
  },
  {
    id: 21,
    name: '21',
    hmtlId: 'teeth21',
  },
  {
    id: 22,
    name: '22',
    hmtlId: 'teeth22',
  },
  {
    id: 23,
    name: '23',
    hmtlId: 'teeth23',
  },
  {
    id: 24,
    name: '24',
    hmtlId: 'teeth24',
  },
  {
    id: 25,
    name: '25',
    hmtlId: 'teeth25',
  },
  {
    id: 26,
    name: '26',
    hmtlId: 'teeth26',
  },
  {
    id: 27,
    name: '27',
    hmtlId: 'teeth27',
  },
  {
    id: 28,
    name: '28',
    hmtlId: 'teeth28',
  },
  {
    id: 31,
    name: '31',
    hmtlId: 'teeth31',
  },
  {
    id: 32,
    name: '32',
    hmtlId: 'teeth32',
  },
  {
    id: 33,
    name: '33',
    hmtlId: 'teeth33',
  },
  {
    id: 34,
    name: '34',
    hmtlId: 'teeth34',
  },
  {
    id: 35,
    name: '35',
    hmtlId: 'teeth35',
  },
  {
    id: 36,
    name: '36',
    hmtlId: 'teeth36',
  },
  {
    id: 37,
    name: '37',
    hmtlId: 'teeth37',
  },
  {
    id: 38,
    name: '38',
    hmtlId: 'teeth38',
  },
  {
    id: 41,
    name: '41',
    hmtlId: 'teeth41',
  },
  {
    id: 42,
    name: '42',
    hmtlId: 'teeth42',
  },
  {
    id: 43,
    name: '43',
    hmtlId: 'teeth43',
  },
  {
    id: 44,
    name: '44',
    hmtlId: 'teeth44',
  },
  {
    id: 45,
    name: '45',
    hmtlId: 'teeth45',
  },
  {
    id: 46,
    name: '46',
    hmtlId: 'teeth46',
  },
  {
    id: 47,
    name: '47',
    hmtlId: 'teeth47',
  },
  {
    id: 48,
    name: '48',
    hmtlId: 'teeth48',
  },
  {
    id: 51,
    name: '51',
    hmtlId: 'teeth51',
  },
  {
    id: 52,
    name: '52',
    hmtlId: 'teeth52',
  },
  {
    id: 53,
    name: '53',
    hmtlId: 'teeth53',
  },
  {
    id: 54,
    name: '54',
    hmtlId: 'teeth54',
  },
  {
    id: 55,
    name: '55',
    hmtlId: 'teeth55',
  },
  {
    id: 61,
    name: '61',
    hmtlId: 'teeth61',
  },
  {
    id: 62,
    name: '62',
    hmtlId: 'teeth62',
  },
  {
    id: 63,
    name: '63',
    hmtlId: 'teeth63',
  },
  {
    id: 64,
    name: '64',
    hmtlId: 'teeth64',
  },
  {
    id: 65,
    name: '65',
    hmtlId: 'teeth65',
  },
  {
    id: 71,
    name: '71',
    hmtlId: 'teeth71',
  },
  {
    id: 72,
    name: '72',
    hmtlId: 'teeth72',
  },
  {
    id: 73,
    name: '73',
    hmtlId: 'teeth73',
  },
  {
    id: 74,
    name: '74',
    hmtlId: 'teeth74',
  },
  {
    id: 75,
    name: '75',
    hmtlId: 'teeth75',
  },
  {
    id: 81,
    name: '81',
    hmtlId: 'teeth81',
  },
  {
    id: 82,
    name: '82',
    hmtlId: 'teeth82',
  },
  {
    id: 83,
    name: '83',
    hmtlId: 'teeth83',
  },
  {
    id: 84,
    name: '84',
    hmtlId: 'teeth84',
  },
  {
    id: 85,
    name: '85',
    hmtlId: 'teeth85',
  },
];
